<script>
    import localizer from "~/common/i18n.js";
    import LoginBox from "../../components/LoginBox.svelte";
    import FlashMessage from "../../components/FlashMessage.svelte";
    import SitesList from "../../components/profile/SitesList.svelte";
    import Form from "../../components/Form.svelte";
    import Button from "../../components/Button.svelte";

    let {
        name,
        serviceName,
        legalEntityName,
        legalEntity,
        agreementLabel,
        prioritizedPublicationDomain,
        privacyPolicyUrl,
        agreementUuid,
        requestedUrl,
        logoutUrl
    } = $props();

    const t = localizer({
        'nb-NO': {
            header: 'Innlogging med aID',
            p1: 'Hei {{name}}',
            p2: 'Siden du logger deg inn på {{serviceName}} med din aID, må du gi tjenesteleverandøren {{legalEntityName}} tilgang til din aID. Tjenestene kan se opplysninger du har oppgitt til aID ({{details}}) samt endringer av disse opplysningene.',
            p2profile: 'som navn, mobilnummer, e-post, fødselsdato og dine personverninnstillinger for aID',
            p2full: 'som navn, mobilnummer, e-post, fødselsdato, tilgang, brukervaner og dine personverninnstillinger for aID',
            servicesHeader: '{{legalEntityName}} tilbyr disse tjenestene:',
            servicesExpand: 'Se alle',
            p3: '{{legalEntityName}} ivaretar ditt personvern ved bruk av tjenestene og du kan lese',
            p3PrivacyPolicyLink: 'deres personvernerklæring her',
            p4: '{{legalEntityName}} kan tilby sømløs innlogging på tvers av sine tjenester uten at du må logge deg inn på nytt.',
            p5: 'Du kan til enhver tid fjerne denne tilgangen på Min aID, og må da på nytt gi tilgang om du vil logge inn.',
            accept: 'Gi tilgang og gå videre',
            switchUser: 'Er du ikke {{name}}?',
            logout: 'Logg inn med en annen aID eller opprett ny',
        },
        'nn-NO':{
            header: 'Innlogging med aID',
            p1: 'Hei {{name}}',
            p2: 'Sidan du loggar deg inn på {{serviceName}} med din aID, må du gi tenesteleverandøren {{legalEntityName}} tilgang til din aID. Tenestene kan sjå opplysningar du har oppgitt til aID ({{details}}) samt endringar av desse opplysningane.',
            p2profile: 'som namn, mobilnummer, e-post, fødselsdato og personverninnstillingane dine for aID',
            p2full: 'som namn, mobilnummer, e-post, fødselsdato, tilgang, brukarvanar og personverninnstillingane dine for aID',
            servicesHeader: '{{legalEntityName}} tilbyr desse tenestene:',
            servicesExpand: 'Sjå alle',
            p3: '{{legalEntityName}} ivaretar personvernet ditt ved bruk av tenestene og du kan lese %s',
            p3PrivacyPolicyLink: 'personvernerklæringa dykkar her',
            p4: '{{legalEntityName}} kan tilby sømløs innlogging på tvers av tenestene sine utan at du må logga deg inn på nytt.',
            p5: 'Du kan til kvar tid fjerne denne tilgangen på Min aID, og må då på nytt gi tilgang om du vil logga inn.',
            accept: 'Gi tilgang og gå vidare',
            switchUser: 'Er du ikkje {{name}}?',
            logout: 'Logg inn med ein annan aID eller opprett ny',
        },
        'da-DK': {
            header: 'Login med aID',
            p1: 'Hej {{name}}',
            p2: 'Da du logger ind på {{serviceName}} med din aID, skal du give tjenesteudbyderen {{legalEntityName}} adgang til din aID. Tjenesterne kan se oplysninger, du har opgivet til aID ({{details}}) samt ændringer af disse oplysninger.',
            p2profile: 'som navn, mobilnummer, e-mail, fødselsdato og dine privatlivsindstillinger for aID',
            p2full: 'som navn, mobilnummer, e-mail, fødselsdato, adgang, brugervaner og dine privatlivsindstillinger for aID',
            servicesHeader: '{{legalEntityName}} tilbyder disse tjenester:',
            servicesExpand: 'Se alle',
            p3: '{{legalEntityName}} varetager dit privatliv ved brug af tjenesterne og du kan læse',
            p3PrivacyPolicyLink: 'deres privatlivspolitik her',
            p4: '{{legalEntityName}} kan tilbyde sømløs login på tværs af sine tjenester uden at du skal logge ind på ny.',
            p5: 'Du kan til enhver tid fjerne denne adgang på Mit aID, og skal da på ny give adgang, hvis du vil logge ind.',
            accept: 'Giv adgang og fortsæt',
            switchUser: 'Er du ikke {{name}}?',
            logout: 'Log ind med en anden aID eller opret ny'
        }
    });
</script>

<LoginBox title={t('header')}>
    <FlashMessage />

    <p>
        {t('p1', {name})}
    </p>

    <p>
        {t('p2', {
            serviceName,
            legalEntityName,
            details: t('p2'+agreementLabel)
        })}
    </p>

    <div class="sites-list-wrapper">
        <h2>
            {t('servicesHeader', {legalEntityName})}
        </h2>

        <SitesList {legalEntity} {prioritizedPublicationDomain} expandButtonText={t('servicesExpand')} />
    </div>

    <p>
        {t('p3', {legalEntityName})} <a href={privacyPolicyUrl}>{t('p3PrivacyPolicyLink')}</a>.
    </p>

    <p>{t('p4', {legalEntityName})}</p>
    <p>{t('p5')}</p>

    <Form action="/aid/personvern/gi_tilgang">
        <input type="hidden" name="agreement_uuid" value={agreementUuid} />
        <input type="hidden" name="requestedUrl" value={requestedUrl} />
        <Button prio={true}
                logName="agreement_for_legal_entity_accept">
            {t('accept')}
        </Button>
    </Form>

    <p>
        {t('switchUser', {name})}
        <a href={logoutUrl}>{t('logout')}</a>
    </p>
</LoginBox>