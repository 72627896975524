<script>
    import localizer from "~/common/i18n.js";
    import Button from "~/components/Button.svelte";
    import LoginBox from "~/components/LoginBox.svelte";

    let { name, continueUrl, logoutUrl } = $props();

    const t = localizer({
        'nb-NO': {
            title: 'Bekreft innlogging',
            text: 'Du er logget inn som <strong>{{name}}</strong>.',
            continue: 'Fortsett',
            logout: 'Logg inn med en annen aID.',
        },
        'nn-NO':{
            title: 'Bekreft innlogging',
            text: 'Du er logga inn som <strong>{{name}}</strong>.',
            continue: 'Fortsett',
            logout: 'Logg inn med ei anna brukar.',
        },
        'da-DK': {
            title: 'Bekræft login',
            text: 'Du er logget ind som <strong>{{name}}</strong>.',
            continue: 'Fortsæt',
            logout: 'Log ind med en anden aID.'
        },
    })
</script>

<LoginBox title={t('title')}>
    <p>{@html t('text', {name: name})}</p>

    <Button prio="true" href={continueUrl}>{t('continue')}</Button>

    <a href={logoutUrl}>{t('logout')}</a>
</LoginBox>

<style>
    p {
        margin-bottom: 0;
    }

    a {
        margin-top: 8px;
    }
</style>