<script>
    import localizer from "~/common/i18n.js";
    import {onMount, tick} from "svelte";
    import {isSupported, performAutoLoginCeremony} from "../common/webAuthn.js";
    import LinkButton from "./LinkButton.svelte";
    import TextInput from "./TextInput.svelte";
    import PhoneInput from "./PhoneInput.svelte";


    const name='username';

    let prioritizeMobile = $state(true);
    let phoneInput = $state();
    let emailInput = $state();
    /**
     * @typedef {Object} Props
     * @property {string} [username]
     * @property {string} [label]
     * @property {boolean} [enableWebauthnLogin]
     * @property {any} [postbackErrorUrl]
     */

    const t = localizer({
        'nb-NO': {
            cellphone: 'Mobilnummer',
            email: 'E-post',
            noPhoneLink: `Jeg har ikke mobiltelefon`,
            usePhoneLink: 'Logg inn med mobiltelefon'
        },
        'nn-NO':{
            cellphone: 'Mobilnummer',
            email: 'E-post',
            noPhoneLink: `Eg har ikkje mobiltelefon`,
            usePhoneLink: 'Logg inn med mobiltelefon'
        },
        'da-DK': {
            cellphone: 'Mobilnummer',
            email: 'E-mail',
            noPhoneLink: `Jeg har ikke mobiltelefon`,
            usePhoneLink: 'Log ind med mobiltelefon'
        },
    });

    /** @type {Props} */
    let {
        username = $bindable(''),
        label = t('cellphone'),
        enableWebauthnLogin = false,
        postbackErrorUrl = null,
        onchange,
    } = $props();

    async function handleUsernameChange(e) {
        username = e.target.value;
        if (prioritizeMobile) {
            if (e.target.value.includes('@')) {
                prioritizeMobile = false;
                await tick();
                emailInput.focus();
            }
        } else if (e.target.value.startsWith('+')) {
            prioritizeMobile = true;
            await tick();
            phoneInput.focus();
        }
    }

    onMount(async () => {
        if (enableWebauthnLogin && await isSupported()) {
            try {
                performAutoLoginCeremony(postbackErrorUrl);
            } catch (e) {
                console.error(e);
            }
        }
    });
</script>
{#if prioritizeMobile}
    <PhoneInput
            bind:this={phoneInput}
            {name}
            bind:value={username}
            {label}
            autocomplete="tel username webauthn"
            oninput={handleUsernameChange}
            {onchange}
    />
{:else}
    <TextInput
            bind:this={emailInput}
            {name}
            bind:value={username}
            type='email'
            label={label.replace(t('cellphone').toLowerCase(), t('email').toLowerCase()).replace(t('cellphone'), t('email'))}
            autocomplete="email username webauthn"
            oninput={handleUsernameChange}
            {onchange}
    />
{/if}
<div class="toggler">
    {#if prioritizeMobile}
        <LinkButton onclick={() => {prioritizeMobile = false}}>{t('noPhoneLink')}</LinkButton>
    {:else}
        <LinkButton onclick={() => {prioritizeMobile = true}}>{t('usePhoneLink')}</LinkButton>
    {/if}
</div>

<style>
    .toggler {
        margin-top: -8px;
        display: flex;
        justify-content: right;
    }
</style>