<script>
    import MessageBox from "@/components/MessageBox.svelte";
    import WebauthnRegistrationButton from "@/components/webauthn/WebauthnRegistrationButton.svelte";
    import InfoBubble from "@/components/InfoBubble.svelte";
    import localizer from "@/common/i18n.js";
    import {AbortedError, ExcludedError} from "@/common/webAuthn.js";

    /**
     * @typedef {Object} Props
     * @property {any} continueUrl
     * @property {boolean} [prio]
     * @property {string} [webauthnInformationUrl]
     * @property {boolean} [showInfoBubble]
     * @property {import('svelte').Snippet} [children]
     */

    /** @type {Props} */
    let {
        continueUrl,
        prio = false,
        webauthnInformationUrl = '',
        showInfoBubble = false,
        children,
        onComplete,
    } = $props();

    let webAuthnRegistrationError = $state(undefined);
    let registrationComplete = $state(false);

    function goToDestination() {
        window.location.href = continueUrl;
    }

    function onRegistrationComplete() {
        registrationComplete = true;
        if (onComplete){
            onComplete();
        }
    }

    function handleWebauthnRegistrationError(err, reason) {
        if (err instanceof ExcludedError) {
            webAuthnRegistrationError = 'excluded';
        } else if (err instanceof AbortedError) {
            webAuthnRegistrationError = 'aborted';
        } else {
            webAuthnRegistrationError = 'unknown';
        }
    }

    const t = localizer({
        'nb-NO': {
            register_complete: 'Passnøkkel ble registrert og kan brukes hver gang du logger inn med aID i denne nettleseren.',
            registration_aborted: 'Passnøkkelen ble ikke registrert. Dette kan skyldes at prosessen ble avbrutt, eller at du allerede har brukt samme nøkkel tidligere. Prøv gjerne igjen.',
            registration_excluded: 'Passnøkkelen ble ikke registrert. Dette skyldes sannsynligvis at du allerede har brukt samme nøkkel tidligere. Prøv gjerne å logge inn uten passord.',
            registration_unknown: 'Passnøkkelen ble ikke registrert. Prøv gjerne igjen.',
            continue_without_passkey: 'Fortsett uten å registrere passnøkkel',
            webauthn_info_title: 'Nå kan du slippe å huske passord',
            webauthn_info_text: 'Nå kan du bruke din egen mobil eller datamaskin som nøkkel. Enklere og tryggere enn passord.',
            webauthn_info_cta: 'Les mer om passordfri innlogging',
            button: 'Start registrering av passnøkkel',
            continue: 'Fortsett',
        },
        'nn-NO':{
            register_complete: 'Passnøkkel vart registrert og kan brukast kvar gong du loggar inn med aID i denne nettlesaren.',
            registration_aborted: 'Passnøkkelen vart ikkje registrert. Dette kan komma av at prosessen vart avbroten, eller at du allereie har brukt same nøkkel tidlegare. Prøv gjerne igjen.',
            registration_excluded: 'Passnøkkelen vart ikkje registrert. Dette kjem sannsynlegvis av at du allereie har brukt same nøkkel tidlegare. Prøv gjerne å logge inn utan passord.',
            registration_unknown: 'Passnøkkelen vart ikkje registrert. Prøv gjerne igjen.',
            continue_without_passkey: 'Fortsett utan å registrere passnøkkel',
            webauthn_info_title: 'No kan du sleppe å hugse passord',
            webauthn_info_text: 'No kan du bruke din eigen mobil eller datamaskin som nøkkel. Enklare og tryggare enn passord.',
            webauthn_info_cta: 'Les meir om passordfri innlogging',
            button: 'Start registrering av passnøkkel',
            continue: 'Fortsett',
        },
        'da-DK': {
            register_complete: 'Adgangsnøgle blev registreret og kan bruges hver gang du logger ind med aID i denne browser.',
            registration_aborted: 'Adgangsnøglen blev ikke registreret. Dette kan skyldes, at processen blev afbrudt, eller at du allerede har brugt samme nøgle tidligere. Prøv gerne igen.',
            registration_excluded: 'Adgangsnøglen blev ikke registreret. Dette skyldes sandsynligvis, at du allerede har brugt samme nøgle tidligere. Prøv gerne at logge ind uden adgangskode.',
            registration_unknown: 'Adgangsnøglen blev ikke registreret. Prøv gerne igen.',
            continue_without_passkey: 'Fortsæt uden at registrere adgangsnøgle',
            webauthn_info_title: 'Nu kan du slippe for at huske adgangskoder',
            webauthn_info_text: 'Nu kan du bruge din egen mobil eller computer som nøgle. Det er nemmere og sikrere end adgangskoder.',
            webauthn_info_cta: 'Læs mere om adgangskodefri indlogning',
            button: 'Start registrering af adgangsnøgle',
            continue: 'Fortsætte'
        }
    })

</script>

{#if !registrationComplete}
    <div>
        {#if webAuthnRegistrationError}
            <MessageBox type="error" cta={t('continue_without_passkey')} onCta={goToDestination}>
                {t('registration_'+webAuthnRegistrationError)}
            </MessageBox>
        {/if}
        <WebauthnRegistrationButton
                {prio}
                onBegin={() => webAuthnRegistrationError = undefined}
                onComplete={onRegistrationComplete}
                onError={handleWebauthnRegistrationError}>{#if children}{@render children()}{:else}{t('button')}{/if}</WebauthnRegistrationButton>

        {#if showInfoBubble}
            <InfoBubble title={t('webauthn_info_title')} text={t('webauthn_info_text')} cta={t('webauthn_info_cta')} ctaUrl={webauthnInformationUrl} />
        {/if}
    </div>
{:else}
    <MessageBox type="success" cta={t('continue')} onCta={goToDestination}>
        {t('register_complete')}
    </MessageBox>
{/if}

<style>
    div {
        display: flex;
        flex-direction: column;
        gap: 18px;
    }
</style>