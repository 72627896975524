<script>
    import localizer from "@/common/i18n.js";
    import LoginBox from "../../components/LoginBox.svelte";
    import FlashMessage from "../../components/FlashMessage.svelte";
    import Form from "../../components/Form.svelte";
    import Button from "../../components/Button.svelte";
    import SessionsListExpansionBox from "../../components/login/SessionsListExpansionBox.svelte";
    import OverlayLink from "../../components/OverlayLink.svelte";

    /**
     * @typedef {Object} Props
     * @property {any} name
     * @property {any} maxSessions
     * @property {any} sessions
     * @property {any} [filteredParams]
     * @property {any} requestedUrl
     * @property {any} termsOfServiceUrl
     * @property {any} privacyPolicyUrl
     * @property {any} customerServiceUrl
     * @property {any} changePasswordUrl
     * @property {any} familiesUrl
     * @property {any} hasFamily
     * @property {number} [availableFamilyAllotments]
     */

    /** @type {Props} */
    let {
        name,
        maxSessions,
        sessions,
        filteredParams = {},
        requestedUrl,
        termsOfServiceUrl,
        privacyPolicyUrl,
        changePasswordUrl,
        familiesUrl,
        hasFamily,
        availableFamilyAllotments = 0
    } = $props();

    const t = localizer({
        'nb-NO': {
            header: 'Du har for mange innlogginger',
            hello: 'Hei {{name}}',
            info1: 'Av hensyn til din sikkerhet er antallet samtidige innlogginger begrenset til {{maxSessions}}.',
            info2: 'Før du kan fortsette må du redusere antall innlogginger.',
            info3: 'Din aID er personlig og skal ikke brukes av noen andre enn deg. Vi vil derfor varsle deg hver gang du har for mange innlogginger, slik at du alltid kan føle deg trygg på at gamle eller uønskede innlogginger fjernes.',
            button: 'Fjern de eldste innloggingene mine',
            changePasswordText: 'Hvis du mistenker at noen har fått tilgang til din aID bør du ',
            changePasswordLink: 'endre passordet',
            familyText: 'Hvis du vil dele abonnementet ditt med andre i din husstand, kan du bruke ',
            familyAllotments: 'Du har fortsatt {{allotments}} ledige plasser.',
            noFamilyText: 'Visste du at hvis dere er flere i samme husstand, så kan dere dele abonnement ved å opprette en ',
            familyLink: 'aID Familie',
            help: 'Trenger du hjelp?',
            helpLearnMore: 'Hvis du lurer på noe kan du lese mer om <a href="{{termsOfServiceUrl}}">brukervilkår</a> og <a href="{{privacyPolicyUrl}}">personvern</a> på aid.no, eller kontakte oss på e-post: <a href="mailto:{{customerServiceEmail}}">{{customerServiceEmail}}</a>',
        },
        'nn-NO':{
            header: 'Du har for mange innloggingar',
            hello: 'Hei {{name}}',
            info1: 'Av omsyn til di tryggleik er talet på samtidige innloggingar begrensa til {{maxSessions}}.',
            info2: 'Før du kan fortsette må du redusere kor mange innloggnigar du har.',
            info3: 'Din aID er personleg og skal ikkje brukast av andre enn deg. Vi vil derfor varsle deg kvar gong du har for mange innloggingar, slik at du alltid kan kjenne deg trygg på at gamle eller uønska innloggingar vert fjerna.',
            button: 'Fjern dei eldste innloggingane mine',
            changePasswordText: 'Viss du mistenker at nokon har fått tilgang til din aID bør du ',
            changePasswordLink: 'endre passordet',
            familyText: 'Viss du vil dele abonnementet ditt med andre i din husstand, kan du bruke ',
            familyAllotments: 'Du har framleis {{allotments}} ledige plassar.',
            noFamilyText: 'Visste du at viss dykk er fleire i same husstand, så kan dykk dele abonnement ved å opprette ein ',
            familyLink: 'aID Familie',
            help: 'Treng du hjelp?',
            "helpLearnMore": "Har du spørsmål, kan du lese meir om <a href=\"{{termsOfServiceUrl}}\">brukarvilkår</a> og <a href=\"{{privacyPolicyUrl}}\">personvern</a> på aid.no, eller ta kontakt med oss på e-post: <a href=\"mailto:{{customerServiceEmail}}\">{{customerServiceEmail}}</a>",
        },
        'da-DK': {
            header: 'Du har for mange logins',
            hello: 'Hej {{name}}',
            info1: 'Af hensyn til din sikkerhed er antallet af samtidige logins begrænset til {{maxSessions}}.',
            info2: 'Før du kan fortsætte, skal du reducere antallet af logins.',
            info3: 'Dit aID er personlig og skal ikke bruges af andre end dig. Vi vil derfor varsle dig hver gang du har for mange logins, så du altid kan føle dig tryg ved, at gamle eller uønskede logins fjernes.',
            button: 'Fjern mine ældste logins',
            changePasswordText: 'Hvis du mistænker, at nogen har fået adgang til dit aID, bør du ',
            changePasswordLink: 'ændre adgangskoden',
            familyText: 'Hvis du vil dele dit abonnement med andre i din husstand, kan du bruge ',
            familyAllotments: 'Du har stadig {{allotments}} ledige pladser.',
            noFamilyText: 'Vidste du, at hvis I er flere i samme husstand, så kan I dele abonnement ved at oprette en ',
            familyLink: 'aID Familie',
            help: 'Har du brug for hjælp?',
            helpLearnMore: 'Hvis du har spørgsmål, kan du læse mere om <a href="{{termsOfServiceUrl}}">brugsbetingelser</a> og <a href="{{privacyPolicyUrl}}">privatlivspolitik</a> på aid.no, eller kontakte os på e-mail: <a href="mailto:{{customerServiceEmail}}">{{customerServiceEmail}}</a>',
        }
    })
</script>

<LoginBox title={t('header')}>
    <FlashMessage />

    <p><b>{t('hello', {name})}</b></p>
    <p>{t('info1', {maxSessions})}</p>
    <p>{t('info2')}</p>

    <Form action="/aid/innlogginger/rydd">
        {#each Object.entries(filteredParams) as [key, value]}
            <input type="hidden" name="{key}" value="{value}" />
        {/each}

        <div class="tidy_sessions">
            <Button logName="tidy_sessions_list_during_login" prio={true}>{t('button')}</Button>
        </div>
    </Form>

    <SessionsListExpansionBox {sessions} {maxSessions} {requestedUrl} />

    <p class="small-text" style="margin-top: 18px">{t('info3')}</p>
    <p class="small-text">
        {t('changePasswordText')}<a href={changePasswordUrl}>{t('changePasswordLink')}</a> ditt.
    </p>

    {#if availableFamilyAllotments > 0 || !hasFamily}
        <p class="small-text">
            {#if hasFamily}
                {t('familyText')}<a href={familiesUrl}>{t('familyLink')}</a>. {t('familyAllotments', {allotments: availableFamilyAllotments})}
            {:else}
                {t('noFamilyText')}<a href={familiesUrl}>{t('familyLink')}</a>.
            {/if}
        </p>
    {/if}

    <div class="help-me-button">
        <OverlayLink header={t('help')} label={t('help')}>
            <p>
                {t('helpLearnMore', { termsOfServiceUrl, privacyPolicyUrl, customerServiceUrl: 'kundeservice@aid.no' })}
            </p>
        </OverlayLink>
    </div>
</LoginBox>