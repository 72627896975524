<script>
    import localizer from "~/common/i18n.js";
    import LoginHeader from "../../components/LoginHeader.svelte";
    import {capitalize} from "../../common/utils.js";
    import Button from "../../components/Button.svelte";
    import Form from "../../components/Form.svelte";

    let {
        vendor,
        recipient,
        externalAccountName,
        otherExternalAccountName,
        filteredParams,
        code
    } = $props();

    const t = localizer({
        'nb-NO': {
            header: `Din aID er allerede tilkoblet en {{vendor}}-konto`,
            notice: `aID-en med mobilnummer {{recipient}} er allerede koblet til {{vendor}}-kontoen til <b>{{otherExternalAccountName}}</b>.`,
            info: `Du logget akkurat inn på {{vendor}} med brukeren til <b>{{externalAccountName}}</b>. Ønsker du å koble denne Facebook-kontoen til din aID i steden?`,
            buttonConfirm: 'Ja, koble denne i steden',
            buttonCancel: 'Nei, den er riktig som den er nå',
        },
        'nn-NO':{
            header: `Din aID er allereie tilkoblet ein {{vendor}}-konto`,
            notice: `aID-en med mobilnummer {{recipient}} er allereie kobla til {{vendor}}-kontoen til <b>{{otherExternalAccountName}}</b>.`,
            info: `Du logga akkurat inn på {{vendor}} med brukaren til <b>{{externalAccountName}}</b>. Ønsker du å koble denne Facebook-kontoen til din aID i staden?`,
            buttonConfirm: 'Ja, koble denne i staden',
            buttonCancel: 'Nei, den er riktig som den er nå',
        },
        'da-DK': {
            header: `Dit aID er allerede koblet til en {{vendor}}-konto`,
            notice: `aID-et med mobilnummer {{recipient}} er allerede koblet til {{vendor}}-kontoen til <b>{{otherExternalAccountName}}</b>.`,
            info: `Du loggede lige ind på {{vendor}} med brugeren til <b>{{externalAccountName}}</b>. Ønsker du at koble denne Facebook-kontoen til dit aID i stedet?`,
            buttonConfirm: 'Ja, koble denne i stedet',
            buttonCancel: 'Nej, den er korrekt som den er nu'
        },
    });
</script>

<LoginHeader title={t('header', {vendor: capitalize(vendor)})} />

<div>
    <p>
        {@html t('notice', {vendor: capitalize(vendor), recipient, otherExternalAccountName})}
    </p>

    <p>
        {@html t('info', {vendor: capitalize(vendor), externalAccountName})}
    </p>

    <Form action="/aid/logg_inn/lagre_{vendor}_kobling">
        {#each Object.entries(filteredParams) as [key, value]}
            <input type="hidden" name="{key}" value="{value}" />
        {/each}
        <input type="hidden" name="code" value={code} />
        <input type="hidden" name="force_update" value="true" />
        <div>
            <Button logName="user_already_connected_confirm" prio={true}>{t('buttonConfirm')}</Button>
        </div>
    </Form>

    <Form action="/aid/logg_inn/lagre_{vendor}_kobling">
        {#each Object.entries(filteredParams) as [key, value]}
            <input type="hidden" name="{key}" value="{value}" />
        {/each}
        <input type="hidden" name="code" value={code} />
        <input type="hidden" name="ignore_external_account_update" value="true" />
        <div>
            <Button logName="user_already_connected_cancel" prio={false}>{t('buttonCancel')}</Button>
        </div>
    </Form>
</div>

<style>
    div {
        display: grid;
        gap: 16px;
    }
</style>
