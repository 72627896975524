<script>
    import localizer from "~/common/i18n.js";

    const t = localizer({
        'nb-NO':  {
            label: 'Eller få engangskode på SMS'
        },
        'da-DK': {
            label: 'Eller få engangskode på SMS'
        }
    });

    let { label = t('label'), url = $bindable(), username } = $props();

    if (username && url) {
        url += `${url.indexOf('?') < 0 ? '?' : '&'}username_from_login=${username}`;
    }
</script>

<a class="forgot-password" href="{url}">
    {label}
</a>

<style>
    a {
        display: block;
        text-align: right;
        font-size: 15px;
        margin-bottom: 20px;
    }
</style>