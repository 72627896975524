<script>
import WebauthnButton from "~/components/webauthn/WebauthnButton.svelte";
import localizer from "~/common/i18n.js";
import {performLoginCeremony} from "~/common/webAuthn.js";

    /**
     * @typedef {Object} Props
     * @property {boolean} [prio]
     * @property {any} [postbackErrorUrl]
     * @property {any} username
     */

    /** @type {Props} */
    let { prio = false, postbackErrorUrl = null, username } = $props();

const t = localizer({
    'nb-NO': {
        title: 'Logg inn uten passord',
    },
    'nn-NO':{
        title: 'Logg inn utan passord',
    },
    'da-DK': {
        title: 'Log ind uden adgangskode',
    }
})

</script>

<WebauthnButton prio={prio} logName="login_webauthn" onclick={(e) => {e.preventDefault(); performLoginCeremony(postbackErrorUrl, username);}} >{t('title')}</WebauthnButton>

<style>
</style>
