<script>
  import { getContext } from "svelte";
  import InlineSVG from "@/components/InlineSVG.svelte";
  import Form from "@/components/Form.svelte";
  import Button from "@/components/Button.svelte";
  import localizer from "@/common/i18n.js";

  let providers = getContext('externalLoginProviders') || [];

  const t = localizer({
    'nb-NO': {
      login_using_provider: 'Logg inn med {{provider}}'
    },
    'nn-NO':{
      login_using_provider: 'Logg inn med {{provider}}'
    },
    'da-DK': {
      login_using_provider: 'Log ind med {{provider}}'
    }
  })
</script>

<div class="providers">
  {#each providers as provider}
    <Form action={provider.url}>
      <Button borderless={true} title={t('login_using_provider', {provider: provider.name})} logName={`login_external_${name}`}>
        <div class="provider">
          <InlineSVG svg={provider.logo} --svg-square-size="24px" />
          <span>{provider.name}</span>
        </div>
      </Button>
    </Form>
  {/each}
</div>

<style>
  .providers {
      display: flex;
      gap: 1rem;
  }
  .providers :global(> *) {
      flex: 1;
  }
  .provider {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 3px;
  }
  .provider > :not(:first-child) {
      flex-grow: 1;
  }
</style>