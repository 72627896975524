<script>
  import localizer from "~/common/i18n.js";
  import FlashMessage from "~/components/FlashMessage.svelte";
  import Form from "~/components/Form.svelte";
  import TextInput from "~/components/TextInput.svelte";
  import Button from "~/components/Button.svelte";
  import LoginBox from "~/components/LoginBox.svelte";
  import SubmitLink from "../../components/login/SubmitLink.svelte";
  import OtpSendStatus from "../../components/OtpSendStatus.svelte";
  import OtpInput from "../../components/OtpInput.svelte";

  let {
    reason,
    credentialUuid,
    recipient,
    filteredParams = {},
    staffIconUrl,
    cancelUrl
  } = $props();

  const reasonTexts = {
    'nb-NO': {
      header: {
        user_activated: "Totrinns innlogging",
        staff: "Totrinns innlogging for Amedia-ansatte",
        vip_customer: "Totrinns innlogging",
        forced: "Ekstra sikkerhetssjekk",
      },
      info: {
        user_activated:
          "Du har aktivert totrinns innlogging for din aID. Vi har sendt deg en engangskode til <b>{{recipient}}</b>, slik at vi kan bekrefte at det er du som logger inn. Tast inn inn koden i feltet under for å fortsette.",
        staff:
          "Din aID har «superkrefter» - tilgang til alle Amedias nettaviser. Vi har derfor sendt deg en engangskode til <b>{{recipient}}</b>, slik at vi kan være helt sikre på at det er du som logger inn.",
        vip_customer:
          "Din aID har ekstra rettigheter i form av tilgang til alle Amedias publikasjoner. Vi har derfor sendt deg en engangskode til <b>{{recipient}}</b>,  slik at vi kan være helt sikre på at det er du som logger inn.",
        forced:
          "Av sikkerhetshensyn har vi sendt deg en engangskode til <b>{{recipient}}</b> slik at vi kan bekrefte at det er du som logger inn.",
      },
    },
    'nn-NO':{
      header: {
        user_activated: "Innlogging med eingongskode",
        staff: "Totrinns innlogging for tilsette i Amedia",
        vip_customer: "Totrinns innlogging",
        forced: "Ekstra tryggleikssjekk",
      },
      info: {
        user_activated:
          "Du har aktivert innlogging med eingongskode for din aID. Vi har sendt deg ein eingongskode til <b>{{recipient}}</b>, slik at vi kan bekrefte at det er du som loggar inn. Tast inn koden i feltet under for å fortsette.",
        staff:
          "Din aID har «superkrefter» - tilgang til alle Amedia sine nettaviser. Vi har derfor sendt deg ein eingongskode til <b>{{recipient}}</b>, slik at vi kan vere heilt sikre på at det er du som loggar inn.",
        vip_customer:
          "Din aID har ekstra rettar i form av tilgang til alle Amedia sine publikasjonar. Vi har difor sendt deg ein einggongskode til <b>{{recipient}}</b>, slik at vi kan vere heilt sikre på at det er du som logger inn.",
        forced:
          "Som ein ekstra tryggleik har vi sendt deg ein eingongskode til <b>{{recipient}}</b> slik at vi kan bekrefte at det er du som loggar deg inn.",
      },
    },
    'da-DK': {
      header: {
        user_activated: "Totrinslogin",
        staff: "Totrinslogin for Amedia-ansatte",
        vip_customer: "Totrinslogin",
        forced: "Ekstra sikkerhedskontrol",
      },
      info: {
        user_activated:
          "Du har aktiveret totrinslogin for din aID. Vi har sendt dig en engangskode til <b>{{recipient}}</b>, så vi kan bekræfte, at det er dig, der logger ind. Indtast koden i feltet nedenfor for at fortsætte.",
        staff:
          "Din aID har «superkræfter» - adgang til alle Amedias netaviser. Vi har derfor sendt dig en engangskode til <b>{{recipient}}</b>, så vi kan være helt sikre på, at det er dig, der logger ind.",
        vip_customer:
          "Din aID har ekstra rettigheder i form af adgang til alle Amedias publikationer. Vi har derfor sendt dig en engangskode til <b>{{recipient}}</b>,  så vi kan være helt sikre på, at det er dig, der logger ind.",
        forced:
          "Af sikkerhedsmæssige årsager har vi sendt dig en engangskode til <b>{{recipient}}</b>, så vi kan bekræfte, at det er dig, der logger ind."
      }
    }
  };

  const t = localizer({
    'nb-NO': {
      header: reasonTexts['nb-NO'].header[reason],
      info: reasonTexts['nb-NO'].info[reason],
      code: "Skriv engangskoden her:",
      button: "Fortsett",
      note: "Mottar du ikke engangskoden?",
      resend: "Trykk her for å få en ny.",
      cancel: "Avbryt innloggingen",
    },
    'nn-NO':{
      header: reasonTexts['nn-NO'].header[reason],
      info: reasonTexts['nn-NO'].info[reason],
      code: "Skriv eingongskoden her:",
      button: "Fortsett",
      note: "Får du ikkje eingongskoden?",
      resend: "Trykk her for å få ein ny.",
      cancel: "Avbryt innlogginga",
    },
    'da-DK': {
      header: reasonTexts['da-DK'].header[reason],
      info: reasonTexts['da-DK'].info[reason],
      code: "Skriv engangskoden her:",
      button: "Fortsæt",
      note: "Modtager du ikke engangskoden?",
      resend: "Tryk her for at få en ny.",
      cancel: "Afbryd login"
    }
  });
</script>

<LoginBox title={t("header")}>
  <OtpSendStatus credentialUUID={credentialUuid}>
    <FlashMessage />

    <p>
      {#if reason === "staff"}
        <img src={staffIconUrl} class="two-factor-staff" alt="Superhelt!" />
      {/if}

      {@html t("info", { recipient })}
    </p>

    <Form action="/aid/logg_inn/verifiser_kode/to-faktor/{credentialUuid}">
      {#each Object.entries(filteredParams) as [key, value]}
        <input type="hidden" name={key} {value} />
      {/each}
      <input type="hidden" name="two_factor_reason" value={reason} />
      <OtpInput label={t("code")} />

      <Button prio={true}>{t("button")}</Button>
    </Form>
  </OtpSendStatus>

  <Form action="/aid/logg_inn/send_engangskode/{credentialUuid}">
    {#each Object.entries(filteredParams) as [key, value]}
      <input type="hidden" name={key} {value} />
    {/each}
    <p class="my-aid-msg aid-login-msg--notice">
      {t("note")}
      <SubmitLink>{t("resend")}</SubmitLink>
    </p>
  </Form>

  <a href={cancelUrl}>{t("cancel")}</a>
</LoginBox>

<style>
    .my-aid-msg {
        padding: 15px;
        font-size: 18px;
        color: var(--black);
    }
</style>