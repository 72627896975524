<script>
    import localizer from "~/common/i18n.js";
    import FlashMessage from "~/components/FlashMessage.svelte";
    import Form from "~/components/Form.svelte";
    import TextInput from "~/components/TextInput.svelte";
    import Button from "~/components/Button.svelte";
    import LoginBox from "~/components/LoginBox.svelte";
    import PasswordInput from "../../components/PasswordInput.svelte";

    let { filteredParams = {}, credentialUuid, code } = $props();

    const t = localizer({
        'nb-NO': {
            header: 'Endre passord',
            info: 'Som en ekstra sikkerhet ber vi deg endre passord, slik at du kan være helt trygg på at ingen uvedkommende kan bruke din aID.',
            password: 'Skriv ønsket passord her:',
            button: 'Fortsett',
            note: 'MERK: Når du endrer passord vil du bli logget ut av alle dine enheter.',
        },
        'nn-NO':{
            header: 'Endre passord',
            info: 'Som ein ekstra tryggleik ber vi deg endre passord, slik at du kan vere heilt trygg på at ingen uvedkommande kan bruke din aID.',
            password: 'Skriv ønska passord:',
            button: 'Fortsett',
            note: 'MERK: Når du endrar passord vil du bli logga ut av alle dine einingar.',
        },
        'da-DK': {
            header: 'Skift adgangskode',
            info: 'Som en ekstra sikkerhedsforanstaltning beder vi dig om at skifte adgangskode, så du kan være helt sikker på, at ingen uvedkommende kan bruge din aID.',
            password: 'Skriv din ønskede adgangskode her:',
            button: 'Fortsæt',
            note: 'MÆRK: Når du skifter adgangskode, bliver du logget ud af alle dine enheder.'
        }
    })

</script>

<LoginBox title={t('header')} >
    <FlashMessage />

    <p>
        {t('info')}
    </p>

    <Form action="/aid/logg_inn/to-faktor/{credentialUuid}/lagre_passord">
        <div>
            {#each Object.entries(filteredParams) as [key, value]}
                <input type="hidden" name="{key}" value="{value}" />
            {/each}
            <input type="hidden" name="code" value={code} />

            <PasswordInput label={t('password')} />

            <Button prio=true logName="enter_password">{t('button')}</Button>

            <i class="small-text">{t('note')}</i>
        </div>
    </Form>
</LoginBox>


<style>
    div {
        display: flex;
        flex-direction: column;
        gap: 18px;
    }
</style>