<script>
  import localizer from "@/common/i18n.js";
  import LoginBox from "~/components/LoginBox.svelte";
  import {onMount, setContext} from "svelte";
  import InfoBubble from "../../components/InfoBubble.svelte";
  import WebauthnLoginButton from "../../components/webauthn/WebauthnLoginButton.svelte";
  import ThirdPartyLogin from "../../components/ThirdPartyLogin.svelte";
  import MessageBox from "../../components/MessageBox.svelte";
  import FlashMessage from "../../components/FlashMessage.svelte";
  import Form from "../../components/Form.svelte";
  import UsernameInput from "../../components/UsernameInput.svelte";
  import Button from "../../components/Button.svelte";
  import PasswordInput from "../../components/PasswordInput.svelte";
  import { formValidationErrors, messages } from "@/stores/flash.js";
  import { isSupported, doNotPrioritizeWebauthn, shouldPrioritizeWebauthn } from "@/common/webAuthn.js";
  import { postWithCredentials } from "@/common/fetchHelpers.js";
  import TurnstileProtected from "~/components/TurnstileProtected.svelte";

  /**
   * @typedef {Object} Props
   * @property {any} showThirdPartyLogin
   * @property {any} publicationDomain
   * @property {any} forgotPasswordUrl
   * @property {string} [webauthnInformationUrl]
   * @property {any} sendCodeUrl
   * @property {any} [filteredParams]
   * @property {any} codeConfirmedUrl
   * @property {any} changeRecipientUrl
   * @property {boolean} [requireSudomode]
   * @property {any} [username]
   * @property {any} [externalLoginProviders]
   * @property {boolean} [isFacebookApp]
   * @property {any} facebookDotsUrl
   * @property {any} facebookExternalBrowserUrl
   * @property {string} [turnstileKey]
   * @property {string} [eventId]
   * @property {string} [eventType]
   */

  /** @type {Props} */
  let {
    showThirdPartyLogin,
    publicationDomain,
    forgotPasswordUrl,
    webauthnInformationUrl = '',
    sendCodeUrl,
    filteredParams = {},
    codeConfirmedUrl,
    changeRecipientUrl,
    requireSudomode = false,
    username = $bindable(undefined),
    externalLoginProviders = [],
    isFacebookApp = false,
    facebookDotsUrl,
    facebookExternalBrowserUrl,
    turnstileKey = "",
  } = $props();
  let hasEnteredPassword = false;

  setContext('externalLoginProviders', externalLoginProviders)

  const t = localizer({
    'nb-NO': {
      header: 'Logg inn eller lag en aID',
      welcome_back: 'Velkommen tilbake!',
      missingUsername: "Du må skrive inn et mobilnummer.",
      invalidUsername: "Dette ser ikke ut til å være et gyldig mobilnummer.",
      loginWithSmsOtp: "Eller få engangskode på SMS",
      otpButtonPhone: "Få engangskode på SMS",
      otpButtonEmail: "Få engangskode på e-post",
      webauthnNotSupported: "Denne nettleseren støtter dessverre ikke passordfri innlogging. For å logge inn må du derfor bruke engangskode eller passord.",
      info_title: 'Nå kan du bruke passordfri innlogging',
      info_text: 'Vil du slippe å huske passord? Nå kan du bruke din egen mobil eller datamaskin som nøkkel. Enklere og tryggere enn passord.',
      info_cta: 'Ja takk, jeg vil lage en passnøkkel',
      facebookWarningTitle: 'Må du logge inn hele tiden?',
      facebookWarningInfo: 'Du er nå inne i Facebook-appen. Denne vil ofte logge deg ut, så vi anbefaler å bruke mobilens egen nettleser.',
      facebookWarningTutorial: 'Trykk på knappen med tre prikker',
      facebookWarningFinally: 'og velg',
      openInExternalBrowser: 'Åpne i ekstern nettleser',
      next: 'Neste',
      login: 'Logg inn',
    },
    'nn-NO':{
      header: 'Logg inn eller lag ein aID',
      welcome_back: 'Velkomen tilbake!',
      missingUsername: "Du må skrive inn eit mobilnummer.",
      invalidUsername: "Dette ser ikkje ut til å være eit gyldig mobilnummer.",
      loginWithSmsOtp: "Eller få eingongskode på SMS",
      otpButtonPhone: "Få engangskode på SMS",
      otpButtonEmail: "Få engangskode på e-post",
      webauthnNotSupported: "Denne nettlesaren tillet diverre ikkje ikke passordfri innlogging. For å logge inn må du difor bruke engongskode eller passord.",
      info_title: 'NYTT: No kan du teste passordfri innlogging',
      info_text: 'Vil du sleppe å hugse passord? No kan du bruke din eigen mobil eller datamaskin som nøkkel. Enklare og tryggare enn passord.',
      info_cta: 'Ja takk, eg vil lage ein passnøkkel',
      facebookWarningTitle: 'Må du logge inn heile tida?',
      facebookWarningInfo: 'Du er no inne i Facebook-appen. Denne vil ofte logge deg ut, så vi anbefalar å bruke mobilens eige nettlesar.',
      facebookWarningTutorial: 'Trykk på knappen med tre prikkar',
      facebookWarningFinally: 'og velg',
      openInExternalBrowser: 'Opne i ein ekstern nettlesar',
      next: 'Neste',
      login: 'Logg inn',
    },
    'da-DK': {
      header: 'Log ind eller opret en aID',
      welcome_back: 'Velkommen tilbage!',
      missingUsername: "Du skal indtaste et mobilnummer.",
      invalidUsername: "Dette ser ikke ud til at være et gyldigt mobilnummer.",
      loginWithSmsOtp: "Eller få engangskode via SMS",
      otpButtonPhone: "Få engangskode via SMS",
      otpButtonEmail: "Få engangskode via e-mail",
      webauthnNotSupported: "Denne browser understøtter desværre ikke adgangskodefri login. For at logge ind skal du derfor bruge engangskode eller adgangskode.",
      info_title: 'Nu kan du bruge adgangskodefri login',
      info_text: 'Vil du slippe at huske adgangskoder? Nu kan du bruge din egen mobil eller computer som nøgle. Enklere og mere sikkert end adgangskoder.',
      info_cta: 'Ja tak, jeg vil oprette en adgangsnøgle',
      facebookWarningTitle: 'Skal du logge ind hele tiden?',
      facebookWarningInfo: 'Du er nu inde i Facebook-appen. Den vil ofte logge dig ud, så vi anbefaler at bruge mobilens egen browser.',
      facebookWarningTutorial: 'Tryk på knappen med tre prikker',
      facebookWarningFinally: 'og vælg',
      openInExternalBrowser: 'Åbn i ekstern browser',
      next: 'Næste',
      login: 'Log ind',
    }
  })

  let passwordHidden = $state(true);
  // Default to show password if we have flash-messages present.
  messages.subscribe((flash) => {
    if (passwordHidden) {
      passwordHidden = flash.length === 0
    }
  })
  let autofill = $state('disabled');
  let logName = $state('one-step-login-next')

  let userExists = $state();
  let userHasPassword = $state();
  let userHasWebauthn = $state();
  let credentialTypes = $state([]);

  let webauthnIsSupported = $state();
  let webauthnShouldBePrioritized = $state();

  function goToRegisterPage(username, createAidUrl) {
    const url = new URL(createAidUrl);
    new URL(location.href).searchParams.forEach((value, name) => {
      url.searchParams.set(name, value);
    })
    url.searchParams.set('username', username);
    window.location = url.href;
  }

  async function findUser(username, publicationDomain) {
    const {user_exists, redirect_url, credential_types} = await postWithCredentials('/aid/logg_inn/finn_bruker',
            {username, publication_domain: publicationDomain})
            .then(response => response.json());
    if (!user_exists) {
      return {user_exists, redirect_url, credential_types};
    }

    userExists = true;

    credentialTypes = credential_types;
    userHasPassword = credential_types.includes('PASSWORD');
    userHasWebauthn = credential_types.includes('WEBAUTHN');
    return {user_exists, redirect_url, credential_types};
  }

  function interceptUsernameFirstLogin(evt) {
    evt.preventDefault();

    try {
      doNotPrioritizeWebauthn();
    } catch (e) {
      console.error(e);
    }

    const username = evt.target.elements.username.value.trim();
    if (!username) {
      formValidationErrors.set({username: [t('missingUsername')]})
      return;
    }
    formValidationErrors.set({})

    findUser(username, publicationDomain)
            .then(({user_exists, redirect_url, credential_types}) => {
              if (!user_exists) {
                return goToRegisterPage(username, redirect_url)
              }

              // Log intention of logging in. Ignoring any errors.
              postWithCredentials('/aid/logg_inn/logg_login_event', {
                intent_id: filteredParams['intent_id'],
                intent_type: filteredParams['intent_type'],
              }).catch(console.error);

              if (!passwordHidden && hasEnteredPassword) {
                evt.target.elements.username.value = username;
                evt.target.submit();
              }

              passwordHidden = false;

              logName = 'one-step-login-submit';
              if (userHasPassword) {
                evt.target.elements?.password?.focus();
              }
            })
            .catch(error => {
              // TODO: MISSING ERROR HANDLING
              console.error(error);

              // Always attempt to submit if we fail some js
              evt.target.submit();
            })
  }

  function handleAutofill(e) {
    hasEnteredPassword = e.target.value !== '';

    if (passwordHidden) {
      passwordHidden = false;
      logName = 'one-step-login-submit-autofilled';
      autofill = 'enabled';
    }
  }

  function goToForgotPasswordUrl(e) {
    e.preventDefault();

    let url = forgotPasswordUrl;
    if (e.target.href) {
      url = e.target.href;
    }

    if (username !== undefined) {
      const sep = url.indexOf('?') >= 0 ? '&' : '?';

      url = url + sep + 'username_from_login=' + encodeURIComponent(username);
    }

    window.location.href = url;
  }

  onMount(() => {
    isSupported().then((supported) => {
      webauthnIsSupported = supported;
      shouldPrioritizeWebauthn().then(shouldPrioritize => webauthnShouldBePrioritized = shouldPrioritize);
    });
    if (username !== undefined && username !== null && username !== "") {
      findUser(username, publicationDomain);
    }
  });
</script>

<LoginBox title={userExists ? t('welcome_back') : t('header')}>
  <FlashMessage/>

  {#if isFacebookApp}
    <MessageBox type="warn" title={t('facebookWarningTitle')}>
      <div class="facebookWarning">
        <p>
          {t('facebookWarningInfo')}
        </p>
        <p>
          {t('facebookWarningTutorial')} (<img class="dots" alt="..." src={facebookDotsUrl} width="55" height="13" />) {t('facebookWarningFinally')}:
        </p>
        <img class="external" alt="{t('openInExternalBrowser')}" src={facebookExternalBrowserUrl} width="306" />
      </div>
    </MessageBox>
  {/if}

  <div class="outer">
    {#if userExists && (!userHasPassword && (!userHasWebauthn || !webauthnIsSupported) || requireSudomode)}
      <Form action={sendCodeUrl}>
        <div>
          {#each Object.entries(filteredParams) as [key, value]}
            <input type="hidden" name="{key}" value="{value}" />
          {/each}
          <input type="hidden" name="code_confirmed_url" value="{codeConfirmedUrl}" />
          <input type="hidden" name="change_recipient_url" value="{changeRecipientUrl}" />

          <UsernameInput bind:username={username} enableWebauthnLogin={true} {webauthnInformationUrl} />

          <TurnstileProtected {turnstileKey} action="login_by_otp"  >
            {#snippet children({ completed, requiresInteraction })}
                        <Button delayUntilTrue={completed || username.startsWith("0000")} {requiresInteraction} prio="true" logName="login_by_otp" type="submit">{username.includes('@') ? t('otpButtonEmail') : t('otpButtonPhone')}</Button>
                                  {/snippet}
                    </TurnstileProtected>
        </div>
      </Form>
    {:else}
      <Form action="/aid/logg_inn/autentiser" onsubmit={interceptUsernameFirstLogin}>
        <div>
          {#each Object.entries(filteredParams) as [key, value]}
            <input type="hidden" name="{key}" value="{value}" />
          {/each}
          <input type="hidden" name="autofill" value={autofill} />

          <UsernameInput bind:username={username} enableWebauthnLogin={true} {webauthnInformationUrl} />

          <PasswordInput onchange={handleAutofill} visuallyHidden={passwordHidden || userHasPassword === false} />

          {#if userExists && !userHasPassword && userHasWebauthn && webauthnIsSupported}
            <WebauthnLoginButton logname="login_known_user_by_webauthn" prio={true} {username} {webauthnInformationUrl} />
          {/if}
          {#if userExists && (userHasWebauthn && webauthnIsSupported || userHasPassword) || !passwordHidden}
            <a class="sms-otp" onclick={goToForgotPasswordUrl} href="{forgotPasswordUrl}">{t('loginWithSmsOtp')}</a>
          {/if}
          {#if !userExists || userHasPassword}
            {#if webauthnShouldBePrioritized !== undefined}
              {#if (!username || username === '') && webauthnShouldBePrioritized}
                <WebauthnLoginButton prio={true} webauthnInformationUrl={webauthnInformationUrl} {username} />
              {:else}
                <Button prio={true} logName={logName}>{passwordHidden ? t('next') : t('login')}</Button>
              {/if}
            {/if}
          {/if}
        </div>
      </Form>
    {/if}
    {#if webauthnIsSupported === false && userHasWebauthn}
      <MessageBox type="warn">{t('webauthnNotSupported')}</MessageBox>
    {/if}
    {#if credentialTypes.includes('PASSWORD') || !credentialTypes.includes('WEBAUTHN')}
      {#if webauthnIsSupported && !((!username || username === '') && webauthnShouldBePrioritized)}
        {#if showThirdPartyLogin && !requireSudomode}
          <ThirdPartyLogin />
        {/if}
        <div class="webauthn">
          <WebauthnLoginButton webauthnInformationUrl={webauthnInformationUrl} {username} />
          <InfoBubble title={t('info_title')} text={t('info_text')} cta={t('info_cta')} ctaUrl={webauthnInformationUrl} />
        </div>
      {/if}
    {/if}
  </div>
</LoginBox>

<style>
  div.webauthn {
    display: grid;
    grid-gap: 20px;
    gap: 20px;
  }

  div {
    display: grid;
    grid-gap: 16px;
    gap: 16px;
  }

  .sms-otp {
    display: block;
    text-align: right;
    margin: .5rem 0;
  }

  .facebookWarning {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    margin-top: 1rem;
  }

  .facebookWarning img {
    max-width: 70%;
  }

  .facebookWarning img.dots {
    vertical-align: middle;
    width: calc(55px / 3);
    height: calc(13px / 3);
  }
</style>