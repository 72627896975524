<script>
    import localizer from "~/common/i18n.js";
    import LoginHeader from "../../components/LoginHeader.svelte";
    import {capitalize} from "../../common/utils.js";
    import Button from "../../components/Button.svelte";
    import Form from "../../components/Form.svelte";
    import ButtonBar from "../../components/ButtonBar.svelte";

    let {
        vendor,
        externalAccountName,
        externalLoginUrl,
        requestedUrl
    } = $props();

    const t = localizer({
        'nb-NO': {
            header: '{{vendor}}-kontoen er tilkoblet en annen aID',
            notice: 'Du er innlogget på {{vendor}} med kontoen til <b>{{externalAccountName}}</b>, og denne er koblet til en annen aID.</b>.',
            step1: '<a target="_blank" href="https://{{vendorDomain}}/">Gå til {{vendor}}</a> (åpnes i ny fane) og logg på med riktig konto.',
            step2: 'Prøv å hente profilbildet en gang til ved å trykke på knappen under:',
            buttonConfirm: 'Prøv igjen',
            buttonCancel: 'Avbryt',
        },
        'nn-NO':{
            header: '{{vendor}}-kontoen er kobla til ein annan aID',
            notice: 'aID-en med mobilnummer {{recipient}} er allereie kobla til {{vendor}}-kontoen til <b>{{otherExternalAccountName}}</b>.',
            step1: '<a target="_blank" href="https://{{vendorDomain}}/">Gå til {{vendor}}</a> (åpnes i ei ny fane) og logg på med riktig konto.',
            step2: 'Prøv å hente profilbildet ein gong til ved å trykke på knappen under:',
            buttonConfirm: 'Prøv igjen',
            buttonCancel: 'Avbryt',
        },
        'da-DK': {
            header: 'Din {{vendor}}-konto er koblet til en anden aID',
            notice: 'Du er logget ind på {{vendor}} med kontoen til <b>{{externalAccountName}}</b>, og den er koblet til en anden aID.</b>.',
            step1: '<a target="_blank" href="https://{{vendorDomain}}/">Gå til {{vendor}}</a> (åbnes i ny fane) og log ind med den korrekte konto.',
            step2: 'Prøv at hente profilbilledet igen ved at trykke på knappen nedenfor:',
            buttonConfirm: 'Prøv igen',
            buttonCancel: 'Annuller',
        }
    })
</script>

<LoginHeader title={t('header', {vendor: capitalize(vendor)})} />

<div>
    <p>
        {@html t('notice', {vendor: capitalize(vendor), externalAccountName})}
    </p>

    <ol>
        <li>{@html t('step1', {vendor: capitalize(vendor), vendorDomain: `www.${vendor}.com`})}</li>
        <li>{t('step2')}</li>
    </ol>

    <Form action={externalLoginUrl}>
        <ButtonBar>
            <Button prio={true} logName="connect_conflict">{t('buttonConfirm')}</Button>
            <Button prio={false} href={requestedUrl}>{t('buttonCancel')}</Button>
        </ButtonBar>
    </Form>
</div>

<style>
</style>
