<script>
    import localizer from "~/common/i18n.js";
    import LoginHeader from "../../components/LoginHeader.svelte";
    import {capitalize} from "../../common/utils.js";
    import Button from "../../components/Button.svelte";
    import Form from "../../components/Form.svelte";
    import ButtonBar from "../../components/ButtonBar.svelte";
    import FlashMessage from "../../components/FlashMessage.svelte";

    let {
        vendor,
        profileDisconnectExternalAccountUrl,
        profileFrontUrl,
        redirectUrl
    } = $props();

    const t = localizer({
        'nb-NO': {
            header: `Fjerne kobling til {{vendor}}?`,
            notice: 'Du er i ferd med å fjerne koblingen mellom aID og {{vendor}}. Du kan ikke bruke {{vendor}} som innlogging på nettsteder som bruker aID lenger.',
            buttonConfirm: 'Fjern kobling',
            buttonCancel: 'Avbryt',
        },
        'da-DK': {
            header: `Fjern kobling til {{vendor}}?`,
            notice: 'Du er ved at fjerne koblingen mellem aID og {{vendor}}. Du kan ikke længere bruge {{vendor}} som login på websteder, der bruger aID.',
            buttonConfirm: 'Fjern kobling',
            buttonCancel: 'Annuller'
        }
    });
</script>

<LoginHeader title={t('header', {vendor: capitalize(vendor)})} />
<FlashMessage />

<div>
    <p>{t('notice', {vendor: capitalize(vendor)})}</p>

    <Form action={profileDisconnectExternalAccountUrl}>
        {#if redirectUrl}
            <input type="hidden" name="redirect_url" value={redirectUrl} />
        {/if}
        <ButtonBar>
            <Button prio={true} href={profileFrontUrl}>{t('buttonCancel')}</Button>
            <Button prio={false}>{t('buttonConfirm')}</Button>
        </ButtonBar>
    </Form>
</div>

<style>
    div {
        display: grid;
        gap: 16px;
    }
</style>
