<script>
  import localizer from "@/common/i18n.js";
  import Button from "~/components/Button.svelte";
  import Form from "@/components/Form.svelte";
  import FlashMessage from "~/components/FlashMessage.svelte";
  import LoginBox from "~/components/LoginBox.svelte";
  import UsernameInput from "../../components/UsernameInput.svelte";
  import TurnstileProtected from "~/components/TurnstileProtected.svelte";

  /**
   * @typedef {Object} Props
   * @property {any} sendCodeUrl
   * @property {any} [filteredParams]
   * @property {any} codeConfirmedUrl
   * @property {any} changeRecipientUrl
   * @property {string} [username]
   * @property {string} [turnstileKey]
   */

  /** @type {Props} */
  let {
    sendCodeUrl,
    filteredParams = {},
    codeConfirmedUrl,
    changeRecipientUrl,
    username = '',
    turnstileKey = ""
  } = $props();

  const t = localizer({
    'nb-NO': {
        header: 'Glemt/endre passord',
        text: 'Tast mobilnummeret ditt, så sender vi deg en engangskode på SMS. Meldingen er gratis.',
        username: 'Mobilnummer',
        button: 'Fortsett',
    },
    'nn-NO':{
        header: 'Gløymt/endre passord',
        text: 'Tast mobilnummeret ditt, så sender vi deg ein eingongskode på SMS. Meldinga er gratis.',
        username: 'Mobilnummer',
        button: 'Fortsett',
    },
    'da-DK': {
        header: 'Glemt/ændre adgangskode',
        text: 'Indtast dit mobilnummer, så sender vi dig en engangskode via SMS. Beskeden er gratis.',
        username: 'Mobilnummer',
        button: 'Fortsæt'
    }
  })
</script>

<LoginBox title={t('header')}>
    <FlashMessage />
    <p>{t('text')}</p>
    <Form action={sendCodeUrl}>
        {#each Object.entries(filteredParams) as [key, value]}
            <input type="hidden" name="{key}" value="{value}" />
        {/each}
        <input type="hidden" name="code_confirmed_url" value="{codeConfirmedUrl}" />
        <input type="hidden" name="change_recipient_url" value="{changeRecipientUrl}" />

        <div>
            <UsernameInput label={t('username')} username={username} />

            <TurnstileProtected {turnstileKey} action="forgot_password"  >
                {#snippet children({ completed, requiresInteraction })}
                <Button delayUntilTrue={completed} {requiresInteraction} prio="true" logName="forgot_password" type="submit">{t('button')}</Button>
                            {/snippet}
            </TurnstileProtected>
        </div>
    </Form>
</LoginBox>

<style>
    div {
        margin-top: 18px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
</style>